import { FC, useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import Lottie from "lottie-react";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";
import { useSelector } from "react-redux";
import { getSemanticSearchDashboard } from "../../../Store/requests/_semanticSearchRequests";
import { siteConfig } from "../../../Services";
import { handleCancelApiRequest } from "../../../Utils";
import { setLoadingStatusAction } from "../../../Store/actions/_commonActions";
import { useDispatch } from "react-redux";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import { useTranslation } from "react-i18next";
const { Paragraph } = Typography;

export const SemanticSearchZeroStates: FC = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { statistics, activeModule } = useSelector((state: any) => state?.semanticSearchReducer);
  const [indexdocuments, setndexdocuments] = useState<Number>(0);
  const [insightgenearted, setinsightgenerated] = useState<Number>(0);
  const [insighydownloaded, setinsightdownloaded] = useState<Number>(0);
  const [fontSize, setFontSize] = useState("52px");
  const [circleFontSize, setCircleFontSize] = useState("54px");
  const [circleDimension, setCircleDimension] = useState("200px");
  const [insightsfontt, setInsightsFont] = useState("14px");
  const [animationHeight,setAnimationHeight]=useState("400px")

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
  
      let newFontSize, newCircleDimension, newCircleFontSize, insightsFont,heightForAnimation;
  
      if (width <= 1435 ) {
        newFontSize = "42px";
        newCircleDimension = "160px";
        newCircleFontSize = "42px";
        insightsFont = "12px";
        heightForAnimation="335px"
      } else {
        newFontSize = "52px";
        newCircleDimension = "200px";
        newCircleFontSize = "54px";
        insightsFont = "14px";
        heightForAnimation="400px"
      }
  
      // Set font size and circle dimension states
      setFontSize(newFontSize);
      setCircleDimension(newCircleDimension);
      setCircleFontSize(newCircleFontSize);
      setInsightsFont(insightsFont);
      setAnimationHeight(heightForAnimation)
    };
  
    window.addEventListener("resize", handleResize);
    handleResize(); // Call initially to set dimensions
  
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  

  console.log(statistics?.insights_generated + "thids us thge onbre required statusutcs");
  useEffect(() => {
    console.log("UseEffect Mount");
    siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_SEMANTIC;

    const fetchData = async () => {
      try {
        // Call your API here `
        const res = await getSemanticSearchDashboard(); // Replace this with your API call
        if (!res) return;
        setndexdocuments(res?.data?.indexed_documents);
        setinsightgenerated(res?.data?.insights_generated);
        setinsightdownloaded(res?.data?.insights_downloaded);
        console.log("API called:", res?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here
      }
    };

    fetchData(); // Call the function to fetch data on component mount
    fetchData();
    return () => {
      console.log("Unmount");
    };
  }, []); // Empt
  const handleStopApiCall = () => {
    handleCancelApiRequest();

    dispatch(setLoadingStatusAction(false));
  };
  console.log(statistics);
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Row style={{ width: "80%", marginLeft: "50px" }}>
        <Col span={8} style={{ alignContent: "end", textAlign: "right", marginLeft: "0px" }}>
          <Lottie id="email" animationData={PROJECT_ICONS.SEARCH_QUERY} loop={true} style={{height:animationHeight}}  />
        </Col>
        <Col span={16}>
          <Col span={24} className="d-flex justify-content-center align-items-center" style={{ marginTop: "50px"}}>
            <Paragraph
              style={{
                marginBottom: "0px",
                fontSize: fontSize,
                color: "#1E4DA1",
                paddingLeft: "10px",
              }}
            >
              Unlock the power of information
            </Paragraph>
          </Col>
          <Col span={24} className="d-flex justify-content-center align-items-center" style={{ marginTop: "30px" }}>
            <div style={{ gap: "25px", display: "flex", justifyContent: "center", textAlign: "center" }}>
              <div
                style={{
                  width: circleDimension,
                  /* Set the width and height to your desired size */ height: circleDimension,
                  borderRadius: "50%" /* Makes the div a circle */,
                  backgroundColor: "#C9DCF7",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  paddingBottom: "10px",
                }}
              >
                <span style={{ fontSize: circleFontSize, fontWeight: "600" }}>{indexdocuments?.toString()}</span>
                <p style={{ color: "#66676B", fontSize: insightsfontt }}>{t(I18N_MODULE_KEYS["semantic.search.landing.content.number"])}</p>
                <p style={{ color: "#66676B", fontSize: insightsfontt }}>{t(I18N_MODULE_KEYS["semantic.search.landing.content.documentsindexed"])}</p>
              </div>
              <div
                style={{
                  width: circleDimension /* Set the width and height to your desired size */,
                  height: circleDimension,
                  borderRadius: "50%" /* Makes the div a circle */,
                  backgroundColor: "#C9DCF7",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  paddingBottom: "10px",
                }}
              >
                <span style={{ fontSize: circleFontSize, fontWeight: "600" }}>{insightgenearted?.toString()}</span>
                <p style={{ color: "#66676B", fontSize: insightsfontt }}>{t(I18N_MODULE_KEYS["semantic.search.landing.content.number"])}</p>
                <p style={{ color: "#66676B", fontSize: insightsfontt }}>{t(I18N_MODULE_KEYS["semantic.search.landing.content.insightsgiven"])}</p>
              </div>
              <div
                style={{
                  width: circleDimension /* Set the width and height to your desired size */,
                  height: circleDimension,
                  borderRadius: "50%" /* Makes the div a circle */,
                  backgroundColor: "#C9DCF7",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  paddingBottom: "10px",
                }}
              >
                <span style={{ fontSize: circleFontSize, fontWeight: "600" }}>15</span>
                <p style={{ color: "#66676B", fontSize: insightsfontt }}>{t(I18N_MODULE_KEYS["semantic.search.landing.content.number"])}</p>
                <p style={{ color: "#66676B", fontSize: insightsfontt }}>
                  {t(I18N_MODULE_KEYS["semantic.search.landing.content.insightsdownloaded"])}
                </p>
              </div>
            </div>
          </Col>
        </Col>
      </Row>
    </div>
  );
};
