import { FC, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';

import { PROJECT_ICONS } from '../../../Assets/Img/_DIcons';
import {
  setActiveModuleActions,
  setAdvancedFilter,
  setAdvancedFilterStatus,
  setBatchQAData,
  setBatchQAFolders,
  setBatchQAStatus,
  setCardDataCollection,
  setConversationId,
  setMessageIId,
  setNextId,
  setPreviousId,
  setProModeScreen,
  setProModeStatus,
  setSearchQuery,
  setActiveCardType,
  setFileActiveCardType,
  setLandingScreen,
  setFileSummariserStatus,
  setSearchType,
} from '../../../Store/actions/_semanticSearchActions';
import { SEMANTIC_SEARCH_MODULES, SEMANTIC_SEARCH_CARD, MED_USER_EMAIL } from '../Components/Core/constants';
import { setLoadingStatusAction } from '../../../Store/actions/_commonActions';

export const FloatingBallSideBarMenu: FC = () => {
  const dispatch = useDispatch();
  const { activeModule } = useSelector((state: any) => state.semanticSearchReducer);
  const { userDetails } = useSelector((state: any) => state.authReducer);

  const [open, setOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (module: string) => {
    if (activeModule !== module) {
      dispatch(setActiveModuleActions(module));
      dispatch(setAdvancedFilterStatus(false));
      dispatch(setAdvancedFilter(false));
      dispatch(setSearchQuery(''));
      dispatch(setCardDataCollection([]));
      dispatch(setConversationId(0));
      dispatch(setMessageIId(0));
      dispatch(setActiveCardType(SEMANTIC_SEARCH_CARD.DOCUMENTS));
      dispatch(setFileActiveCardType(SEMANTIC_SEARCH_CARD.DOCUMENTS));
      dispatch(setNextId(0));
      dispatch(setPreviousId(0));
      dispatch(setProModeStatus(false));
      dispatch(setProModeScreen(false));
      dispatch(setBatchQAStatus(false));
      dispatch(setBatchQAData([]));
      dispatch(setBatchQAFolders([]));
      dispatch(setLoadingStatusAction(false));
      dispatch(setLandingScreen(true));
      dispatch(setFileSummariserStatus(false));
      dispatch(setSearchType("Semantic"))
    }
    setOpen(false); // Close the sidebar menu
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    const handler = open ? document.addEventListener("click", handleOutsideClick) : document.removeEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [open]);

  return (
    <div style={{ zIndex: 2, position: 'absolute', margin: '9px', top: '100px' }} ref={menuRef}>
      <Tooltip title="Side-Menu">
        <img
          src={PROJECT_ICONS.FLOATINGICON}
          style={{ height: '50px', cursor: 'pointer' }}
          onClick={() => setOpen(!open)}
        />
      </Tooltip>
      {open && (
        <div style={{ background: 'white', paddingTop: '15px', borderRadius: '5px', padding: '5px', height: '450px' }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', marginTop: '20px', cursor: 'pointer' }}
            onClick={() => handleMenuItemClick(SEMANTIC_SEARCH_MODULES.SEMANTIC_SEARCH)}
          >
            <img src={PROJECT_ICONS.HOME} style={{ height: '15px', width: '15px' }} />
            <div>Search</div>
          </div>
          {userDetails?.email !== MED_USER_EMAIL && (
            <div
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', cursor: 'pointer' }}
              onClick={() => handleMenuItemClick(SEMANTIC_SEARCH_MODULES.COMMON_ARTEFACTS)}
            >
              <img src={PROJECT_ICONS.ARTEFACTS} style={{ height: '15px', width: '15px' }} />
              <div>Artefacts</div>
            </div>
          )}
          <div
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', cursor: 'pointer' }}
            onClick={() => handleMenuItemClick(SEMANTIC_SEARCH_MODULES.FILE_MANAGEMENT_SYSTEM)}
          >
            <img src={PROJECT_ICONS.FILES} style={{ height: '15px', width: '15px' }} />
            <div>Files</div>
          </div>
        </div>
      )}
    </div>
  );
};
