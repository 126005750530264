import { toastError } from "../../Components/Toastify";
import { siteConfig } from "../../Services";
import {
  checkExpirationOfToken,
  globalConstant,
  handleAPIErrors,
  handleGetDataFromApi,
  handlePostDataFromApi,
  handlePutDataFromApi,handleDeleteDataFromApi
} from "../../Utils";

import { apiResponse } from "../../Utils/_gTypes";
import { setLoadingStatusAction, setNotificationMsgAction } from "../actions/_commonActions";

const getUserID = () => "0";
export const getDocuments = async (isLoading?: any, sendMessage?: any) => {
  try {
  
    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_DOCUMENT_FILTER+'?folder=', isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
        return undefined;
    }
 
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getFileManagementDocuments = async (isLoading?: any, sendMessage?: any) => {
  try {
  
    const res: apiResponse = await handleGetDataFromApi(siteConfig.FILTER_FOLDER+'?folder=', isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
        return undefined;
    }
 
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getImageDocuments = async (isLoading?: any, sendMessage?: any) => {
  try {
  
    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_IMAGE_DOCUMENT_FILTER+'?folder=', isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
        return undefined;
    }
 
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const searchQueryPromptRequest = async (body: { prompt: string; filters: string[] }, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.GET_SIMILAR_DOCS,
      { ...body, user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getSemanticSearchRequest = async (body: { query: string; filters: string[] }, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.GET_SIMILARITY_SEARCH,
      { ...body, user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getSemanticSearch = async (body: { prompt: string;filters: string[],output_format?: any, k?:Number,column_names?:string[] }, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.SEMANTIC_SEARCH,
      { ...body, user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getDocument = async (semantic_id: number, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_DOCUMENT + `/${semantic_id}`, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAnswerDataByIdrequest = async (answer_id: number, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_SIMILARITY_SEARCH + `/${answer_id}`, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getSemanticSummaryRequest = async (body: { filename: string }, isLoading?: any, sendMessage?: any) => {
  try {
    const { filename } = body;

    const res: apiResponse = await handleGetDataFromApi(`${siteConfig.GET_SUMMARISED_DOC}?filename=${filename}`, isLoading ? isLoading : undefined);
    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const setAddToReportRequest = async (body: { id: number }, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.ADD_TO_REPORT,
      { ...body, user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const setGenerateReportRequest = async (body: { answer_ids: number[] }, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.GENERATE_REPORT,
      { ...body, user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getReportByIdRequest = async (report_id: number, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_REPORT_BY_ID + `/${report_id}`, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};
export const semanticIntereseted = async (body: { id: number; interested:any }, isLoading?: any) => {
  // /image-upload?user_id=11&story_id=1&storyboard_id=7
  try{
  const res: apiResponse = await handlePutDataFromApi(
    siteConfig.GET_MESSAGE_INTERESTED , { ...body },
    isLoading ? isLoading : undefined
  );

  const { error, data } = res;

  if (error || res?.status !== 200) { 
    // handleAPIErrors(res); 
    return undefined;
  }
  
  return data;}
  catch
  (error){
    console.log(error)
  }
}; 
export const semanticQaIntereseted = async (body: { id: number; interested:any }, isLoading?: any) => {
  // /image-upload?user_id=11&story_id=1&storyboard_id=7
  try{
  const res: apiResponse = await handlePutDataFromApi(
    siteConfig.SEMANTIC_QA_INTERESTED , { ...body },
    isLoading ? isLoading : undefined
  );

  const { error, data } = res;

  if (error || res?.status !== 200) { 
    // handleAPIErrors(res);
    return undefined;
  } 
  
  return data;}
  catch
  (error){
    console.log(error)
  }
}; 
export const semanticQaFeedBack = async (body: { id: number; feedback:any }, isLoading?: any) => {
  // /image-upload?user_id=11&story_id=1&storyboard_id=7
  try{
  const res: apiResponse = await handlePutDataFromApi(
    siteConfig.SEMANTIC_QA_INTERESTED , { ...body },
    isLoading ? isLoading : undefined
  );

  const { error, data } = res;

  if (error || res?.status !== 200) { 
    // handleAPIErrors(res);
    return undefined;
  } 
  
  return data;}
  catch
  (error){
    console.log(error)
  }
}; 
export const getSemanticSearchDashboard = async (isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.GET_DASHBOARD,
      {user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const PostSemanticChat = async (body: {filename:string,question:string,chat_id?:any },isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.CHAT_DOCUMENT,
      {...body,user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const PostSemanticChatSession = async (body: {filename:string,question:string,chat_id:any },isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.CHAT_DOCUMENT,
      {...body,user_id: getUserID() },
      isLoading ? isLoading : undefined 
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getAnswerForChat = async (id: number, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.CHAT_DOCUMENT + `/${id}`, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const PostCreateFolders = async (body: {filename:string,question:string },isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.CHAT_DOCUMENT,
      {...body,user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const handleCreateFolder = async (body: {folder_name:string,parent_folder_id?:number } , isLoading?: any, sendMessage?: any) => {
  const requestBody = { 
    parent_folder_id: body.parent_folder_id, 
  };
  try {

    const res: apiResponse = await handlePostDataFromApi(siteConfig.FOLDER + `/${body.folder_name}`, {...requestBody ,user_id: getUserID()},
    isLoading ? isLoading : undefined);

    const { error, reason } = res;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const uploadFiles = async (body:FormData,name:string,num:number, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.UPLOAD + `/0/${name}/${num}`,
    body,
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};
export const getChats = async (body:{filename:string,chat_id?:number}, isLoading?: any, sendMessage?: any) => {
  try {
    const encodedFileName = encodeURIComponent(body.filename);
    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_CHAT + `?user_id=0&filename=${encodedFileName}&chat_id=`, isLoading ? isLoading : undefined);
    
    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getChatsSpecific = async (body:{filename:string,chat_id?:number}, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_CHAT + `?user_id=0&filename=${body.filename}&chat_id=${body.chat_id}`, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getQuestionRecommendation = async (body:{filename:string}, isLoading?: any, sendMessage?: any) => {
  try {
    const encodedFileName = encodeURIComponent(body.filename);

    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_QUESTION_RECOMMENDATION + `?filename=${encodedFileName}`, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));
 
      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getInsightsHistory = async (body: { page:number,keyword:string }, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.GET_INSIGHT_HISTORY,
      { ...body, user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined; 
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
}; 
export const getChatsHistory = async (body: { page:number,keyword:string }, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.GET_CHAT_HISTORY,
      { ...body, user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined; 
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
}; 
export const getBilling = async ( isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_BILLING, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getBillingMonthly = async ( isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_BILLING+`?monthly=true`, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getBillingReport = async ( isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_BILLING_REPORT, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getBillingMonthlyReport = async ( isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_BILLING_REPORT+`?monthly=true`, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getSemanticSearchImage = async (body: { prompt: string}, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.SEMANTIC__IMAGE_SEARCH,
      { ...body, user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined; 
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getImageFromId = async (answer_id: number, isLoading?: any, sendMessage?: any) => {
  try {
    const url = `${siteConfig.GET_IMAGE_SEMANTIC}?image_id=${answer_id}`;

    // Make the GET request
    const res = await handleGetDataFromApi(url, isLoading ? isLoading : undefined);
    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const handleImageCreateFolder = async (folder_name: string, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(siteConfig.IMAGEFOLDER + `/${folder_name}`, isLoading ? isLoading : undefined);

    const { error, reason } = res;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res;
  } catch (error) {
    console.log(error);
  }
}; 
export const uploadImageFiles = async (body:FormData,name:string, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.IMAGEUPLOAD + `/${name}`,
    body,
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const uploadFilesToFMS = async (body:FormData,name:string,num:any, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.UPLOADFILES + `/${name}/${num}`,
    body,
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getAnswerDataByIdImagerequest = async (answer_id: number,semanticid:number, isLoading?: any, sendMessage?: any) => {
  try {
    const url = `${siteConfig.GET_IMAGE_DRAWER}?image_id=${semanticid}&file_id=${answer_id}`;

    // Make the GET request
    const res = await handleGetDataFromApi(url, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const semanticImageInterested = async (body: { image_id: number; file_id:number ; interested:any }, isLoading?: any) => {
  // /image-upload?user_id=11&story_id=1&storyboard_id=7
  try{
  const res: apiResponse = await handlePutDataFromApi(
    siteConfig.SEMANTIC_IMAGE_INTERESTED , { ...body },
    isLoading ? isLoading : undefined
  ); 

  const { error, data } = res;

  if (error || res?.status !== 200) { 
    // handleAPIErrors(res);
    return undefined;
  }
  
  return data;}
  catch
  (error){
    console.log(error)
  }
}; 
export const getMessage = async (message_id: number, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_MESSAGE + `?message_id=${message_id}`, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));
 
      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getProModeTags = async (body: { question: string}, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.PRO_MODE,
      { ...body, user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined; 
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getAllConversations = async (conversation_id: number, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_MESSAGE + `?conversation_id=${conversation_id}`, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));
 
      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const startConversation = async (isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.START_CONVERSATION,
      {user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined; 
    }

    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};
export const semanticPPTUpdate = async (body:{slide_id: number;title:any;content:any}, isLoading?: any) => {
  try{
  const res: apiResponse = await handlePutDataFromApi(
    siteConfig.UPDATE_REPORT_PPT , { ...body },
    isLoading ? isLoading : undefined
  );

  const { error, data } = res;

  if (error || res?.status !== 200) { 
    // handleAPIErrors(res);
    return undefined;
  } 
  
  return data;}
  catch
  (error){
    console.log(error)
  }
}; 
export const getUpdatedSlide = async (slide_id: number, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.UPDATE_REPORT_PPT + `/${slide_id}`, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));
 
      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const generatePdf = async (body:{conversation_id:number,regenerate:boolean},isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.GENERATE_PDF,
      {...body,user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined; 
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const semanticPDFUpdate = async (body:{pdf_id: number;content:any}, isLoading?: any) => {
  try{
  const res: apiResponse = await handlePutDataFromApi(
    siteConfig.UPDATE_REPORT_PDF , { ...body },
    isLoading ? isLoading : undefined
  );

  const { error, data } = res;

  if (error || res?.status !== 200) { 
    // handleAPIErrors(res);
    return undefined;
  } 
  
  return data;}
  catch
  (error){
    console.log(error)
  }
}; 
export const getChunkaByName = async (body:{file_name: string,sse_id:number,is_internet_search:boolean}, isLoading?: any, sendMessage?: any) => {
  try {
    const encodedFileName = encodeURIComponent(body.file_name);

    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_CHUNKS + `/${body.sse_id}?is_internet_search=${body.is_internet_search}&filename=${body.file_name}`, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const syncS3 = async (isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.SYNCS3,
    isLoading ? isLoading : undefined
  );

  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }

  return data;
};

export const getAllNotificationForUser = async (isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.GET_ALL_NOTIFICATION+`?user_id=0`,     isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));
 
      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const UpdateReadMeStatus = async (isLoading?: any) => {
  try{
  const res: apiResponse = await handlePutDataFromApi(
    siteConfig.UPDATE_READ_STATUS_FOR_NOTIFICATION + `?user_id=0` ,
    isLoading ? isLoading : undefined
  );

  const { error, data } = res;

  if (error || res?.status !== 200) { 
    // handleAPIErrors(res);
    return undefined;
  } 
  
  return data;}
  catch
  (error){
    console.log(error)
  }
}; 

export const getAllArtifacts = async (isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.ARTIFACT + `/all?file_name=`, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));
 
      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getArtifactById = async (body:{artifact_id:number},isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.ARTIFACT + `?artifact_id=${body.artifact_id}`, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));
 
      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getArtifactByFileName = async (body:{file_name:string},isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handleGetDataFromApi(siteConfig.ARTIFACT + `/all?file_name=${body.file_name}`, isLoading ? isLoading : undefined);

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));
 
      return undefined;
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export const exportArtefactReport = async (body:{semantic_id:number},isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.EXPORT_ARTIFACT,
      {...body,user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined; 
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const UpdateArtifactData = async (body:{data:string,artifact_id:number},isLoading?: any) => {
  try{
  const res: apiResponse = await handlePutDataFromApi(
    siteConfig.ARTIFACT,{ ...body },
    isLoading ? isLoading : undefined
  );

  const { error, data } = res;

  if (error || res?.status !== 200) { 
    // handleAPIErrors(res);
    return undefined;
  } 
  
  return data;}
  catch
  (error){
    console.log(error)
  }
}; 

export const uploadFilesForBatchQA = async (body:FormData, isLoading?: any) => {
  const res: apiResponse = await handlePostDataFromApi(
    siteConfig.START_CONVERSATION + siteConfig.UPLOADBATCHQA ,
    body,
    isLoading ? isLoading : undefined
  );
  const { status, data } = res;

  if (status !== 200) {
    // handleAPIErrors(res);
    return undefined;
  }
  return data;
};

export const getMedicalReferenceChunks = async (body: { question: string,filters:any[] }, isLoading?: any, sendMessage?: any) => {
  try {
    const res: apiResponse = await handlePostDataFromApi(
      siteConfig.MEDICALREFERENCECHUNKS,
      {...body,user_id: getUserID() },
      isLoading ? isLoading : undefined
    );

    const { error, reason } = res?.data;

    if (error || res?.status !== 200) {
      if (sendMessage) sendMessage(setNotificationMsgAction(reason));

      sendMessage(setLoadingStatusAction(false));

      return undefined; 
    }

    return res?.data;
  } catch (error) {
    console.log(error);
  }
};