import { SEMANTIC_SEARCH_MODULES , SEMANTIC_SEARCH_CARD} from "../../Pages/Semantic Search/Components/Core/constants";
import {
  SET_SEARCH_QUERY,
  SET_SEARCH_BAR_STATUS,
  SET_ENCODED_SEARCH_QUERY,
  SET_CARDS_DATA, 
  SET_TAB_LIST,
  SET_ADVANCED_FILTER,
  SET_SELECTED_REPORTS,
  SET_REFETCH_LIST,
  SET_ADVANCEDFILTER_LIST,
  SET_ADVANCEDFILTER_STATUS,
  SET_BUTTON_TEXT,
  SET_SEMANTIC_SEARCH_ID,
  SET_INITIAL_FOLDERS,
  SET_CARD_DATA,
  SET_FORMAT,
  SET_K_VALUE,
  SET_STATISTICS,SET_ENCODED_SEMANTIC_SEARCH_ID,SET_ACTIVE_MODULE,
  SET_CREATE_NEW_FOLDER,
  SET_FILE_LENGTH,SET_UPLOAD_FILE_STATUS,SET_REGENERATE_STATUS,
  SET_IMAGE_CARDS_DATA,
  SET_CARD_TYPE,SET_FILE_CARD_TYPE,SET_SEMANTIC_SEARCH_IMAGE_ID,
  SET_CARD_DATA_COLLECTION,
  SET_LANDING_SCREEN,SET_CONVERSATION_ID,SET_NEXT_ID,SET_PREVIOUS_ID,
  SET_PRO_MODE_SCREEN,SET_PRO_MODE_STATUS,
  SET_MESSAGE_ID,
  SET_ENCODED_SEMANTIC_SEARCH_ID_KVALUE,
  SET_EDITABLE_CONTENT,
  SET_LOADING_FOR_REPORT_GENERATION,
  SET_REPORT_SLIDES,
  SET_TYPE_FOR_REPORT_GENERATION,
  SET_PDF_REPORTS,
  SET_LOADING_FOR_SWITCHING,
  SET_SEARCH_TYPE,
  SET_LLM_MODEL,
  SET_AUDIO_CARDS_DATA,
  SET_VIDEO_CARDS_DATA,
  SET_SYNCED_NOTIFICATIONS,
  SET_BATCH_QA_STATUS,
  SET_BATCH_QA_DATA,
  SET_BATCH_QA_FOLDERS,
  SET_SUMMARISER,
  SET_FILES_FOR_CHAT
} from "../constants/_semanticSearchConstants";
type Folder = {
  id: number;
  name: string;
  files: Record<string, string>;
};
type InitialState = {
  searchQuery: string;
  encodedSearchQuery: string;
  searchBarHasValue: boolean;
  cardsData: any[];
  tabList: any[];
  advancedFilter: boolean;
  selectedReports: number[];
  refetchList: boolean;
  advancedFilterList: string[];
  advancedFilterStatus: boolean;
  buttonText: string;
  initialFolders: Folder[];
  semanticSearchid: Number;
  cardData: object;
  kvalue:Number;
  Format:any;
  statistics:object;
  semanticEncodedSearchid: Number;
  activeModule: string;
  file: any[];
  createnewfolderstatus:Boolean;
  uploadFileStatus:Boolean;
  regeneratestatus:Boolean;
  cardsImageData: any[];
  cardType: string;
  filecardType: string;
  semanticSearchImageId: Number;
  cardDataCollection:any[];
  landingScreen : boolean;
  conversationId:Number;
  previousId:Number;
  nextId:Number;
  proModeStatus:boolean;
  proModeScreen:boolean;
  messageId:number;
  semanticEncodedSearchidkvalue: Number;
  editReportGenerationScreen:boolean;
  loadingForReportGeneration:boolean;
  reportType:string;
  reportSlides:object;
  reportPdf:object;
  loadingForSwitching:boolean;
  searchType:string;
  llmModel:string;
  cardsVideoData: any[];
  cardsAudioData: any[];
  syncedNotificationsData:any[];
  fileForChatWithDoc:string[];
  batchQAStatus:boolean;
  batchQAData:any[];
  batchQAFolders:any[];
  summariserStatus:boolean;
};

const initialState: InitialState = {
  searchQuery: "",
  encodedSearchQuery: "",
  searchBarHasValue: false,
  cardsData: [],
  tabList: [],
  advancedFilter: false,
  selectedReports: [],
  refetchList: false,
  advancedFilterList: [],
  advancedFilterStatus: false,
  buttonText: "Advanced Filter",
  initialFolders: [],
  semanticSearchid: 0,
  cardData: {},
  kvalue: 10,
  Format: 1,
  statistics:{},
  semanticEncodedSearchid:0,
  activeModule: SEMANTIC_SEARCH_MODULES.SEMANTIC_SEARCH,
  file:[],
  createnewfolderstatus:false,
  uploadFileStatus:false,
  regeneratestatus:false,
  cardsImageData: [],
  cardType: SEMANTIC_SEARCH_CARD.DOCUMENTS,
  filecardType: SEMANTIC_SEARCH_CARD.DOCUMENTS,
  semanticSearchImageId: 0,
  cardDataCollection:[],
  landingScreen :true,
  conversationId:0,
  previousId:0,
  nextId:0,
  proModeStatus:false,
  proModeScreen:false,
  messageId:0,
  semanticEncodedSearchidkvalue: 0,
  editReportGenerationScreen:false,
  loadingForReportGeneration:false,
  reportType:"",
  reportSlides:{},
  reportPdf:{},
 loadingForSwitching:false,
 searchType:'Semantic',
  llmModel:'gpt-4o',
  cardsAudioData: [],
  cardsVideoData: [],
  syncedNotificationsData:[],
  fileForChatWithDoc:[],
  batchQAStatus:false,
  batchQAData:[],
  batchQAFolders:[] ,
  summariserStatus:false,
};

export default function semanticSearchReducer(state: InitialState = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: payload
      };
    case SET_ENCODED_SEARCH_QUERY:
      return {
        ...state,
        encodedSearchQuery: payload
      };
    case SET_SEARCH_BAR_STATUS:
      return {
        ...state,
        searchBarHasValue: payload
      };
    case SET_CARDS_DATA:
      return {
        ...state,
        cardsData: payload
      };
    case SET_TAB_LIST:
      return {
        ...state,
        tabList: payload
      };
    case SET_ADVANCED_FILTER:
      return {
        ...state,
        advancedFilter: payload
      };
    case SET_SELECTED_REPORTS:
      return {
        ...state,
        selectedReports: payload
      };
    case SET_REFETCH_LIST:
      return {
        ...state,
        refetchList: payload
      };

    case SET_ADVANCEDFILTER_LIST:
      return {
        ...state,
        advancedFilterList: payload
      };
    case SET_ADVANCEDFILTER_STATUS:
      return {
        ...state,
        advancedFilterStatus: payload
      };
    case SET_BUTTON_TEXT:
      return {
        ...state,
        buttonText: payload
      };
    case SET_INITIAL_FOLDERS:
      return {
        ...state,
        initialFolders: payload
      };
    case SET_SEMANTIC_SEARCH_ID:
      return {
        ...state,
        semanticSearchid: payload
      };
      case SET_ENCODED_SEMANTIC_SEARCH_ID:
        return {
          ...state,
          semanticEncodedSearchid: payload
        };
        
    case SET_CARD_DATA:
      return {
        ...state,
        cardData: payload
      };
      case SET_K_VALUE:
        return {
          ...state,
          kvalue: payload
          };
      case SET_FORMAT:
          return {
           ...state,
          Format: payload
            };
            case SET_STATISTICS:
              return {
                ...state,
                statistics: payload
              };
              case SET_ACTIVE_MODULE:
        return {
          ...state,
          activeModule: payload
        };
        case SET_FILE_LENGTH:
          return {
            ...state,
            file: payload
          };
      
          case SET_CREATE_NEW_FOLDER:
            return {
              ...state,
              createnewfolderstatus: payload
            };
            case SET_UPLOAD_FILE_STATUS:
              return {
                ...state,
                uploadFileStatus:payload
              };
              case SET_REGENERATE_STATUS:
              return {
                ...state,
                regeneratestatus:payload
              };
              case SET_IMAGE_CARDS_DATA:
      return {
        ...state,
        cardsImageData: payload
      };
      case SET_CARD_TYPE:
        return {
          ...state,
          cardType: payload
        };
        case SET_FILE_CARD_TYPE:
        return {
          ...state,
          filecardType: payload
        };
        case SET_SEMANTIC_SEARCH_IMAGE_ID:
          return {
            ...state,
            semanticSearchImageId: payload
          };
          case SET_CARD_DATA_COLLECTION:
            return {
              ...state,
              cardDataCollection: payload
            }; 
            case SET_LANDING_SCREEN:
            return {
              ...state,
              landingScreen: payload
            };
            case SET_CONVERSATION_ID:
              return {
                ...state,
                conversationId: payload
              };
              case SET_PREVIOUS_ID:
                return {
                  ...state,
                  previousId: payload
                };
              case SET_NEXT_ID:
                  return {
                    ...state,
                  nextId: payload
                  };
              case SET_PRO_MODE_SCREEN:
                    return {
                      ...state,
                    proModeScreen: payload
                    };
               case SET_PRO_MODE_STATUS:
                      return {
                        ...state,
                      proModeStatus: payload
                      };
            case SET_MESSAGE_ID:
              return {
                ...state,
                messageId: payload 
            };
            case SET_ENCODED_SEMANTIC_SEARCH_ID_KVALUE:
              return {
                ...state,
                semanticEncodedSearchidkvalue: payload
              };
              case SET_EDITABLE_CONTENT:
                return {
                  ...state,
                  editReportGenerationScreen: payload
                };
                case SET_LOADING_FOR_REPORT_GENERATION:
              return { 
                ...state,
                loadingForReportGeneration: payload
              };
              case SET_TYPE_FOR_REPORT_GENERATION:
              return {
                ...state,
                reportType:payload
              };
              case SET_REPORT_SLIDES:
              return {
                ...state,
                reportSlides:payload
              };
              case SET_PDF_REPORTS:
              return {
                ...state,
                reportPdf:payload
              };
              case SET_LOADING_FOR_SWITCHING:
              return { 
                ...state,
                loadingForSwitching: payload
              };
              case SET_SEARCH_TYPE:
              return { 
                ...state,
                searchType: payload
              };
              case SET_LLM_MODEL:
                return { 
                  ...state,
                  llmModel: payload
                };
                case SET_VIDEO_CARDS_DATA:
                  return {
                    ...state,
                    cardsVideoData: payload
                  };
                  case SET_AUDIO_CARDS_DATA:
                    return {
                      ...state,
                      cardsAudioData: payload
                    };
                    case SET_SYNCED_NOTIFICATIONS:
                      return {
                        ...state,
                        syncedNotificationsData: payload
                      };
                      case SET_FILES_FOR_CHAT:
                      return {
                        ...state,
                        fileForChatWithDoc: payload
                      };
                      case SET_BATCH_QA_STATUS:   
                      return {
                        ...state,
                        batchQAStatus: payload
                      };
                      case SET_BATCH_QA_DATA:   
                      return {
                        ...state,
                        batchQAData: payload
                      };
                      case SET_BATCH_QA_FOLDERS:   
                      return {
                        ...state,
                        batchQAFolders: payload
                      };
                     case SET_SUMMARISER:
                      return {
                        ...state,
                        summariserStatus: payload
                      };

             
    default:
      return state;
  } 
} 
 